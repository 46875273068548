<template>
  <div>
    <comentario
      v-for="comentario in comentarios"
      :key="comentario._id"
      :comentario="comentario"
      :entregaActividad="entregaActividad"
      :idActividad="idActividad"
      :idClase="idClase"
      :idCurso="idCurso"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :alumnos="alumnosConComentarios"
      :calificaciones="calificaciones"
      :equipos="equipos"
      :permisoEscritura="permisoEscritura"
      :cursoArchivado="cursoArchivado"
      class="mb-3"
    ></comentario>
  </div>
</template>

<script>
import Comentario from "./Comentario.vue";
import { listadoEntregasActividadService } from "../cursos/actividades/listadoEntregas/entregas.service";
import { ordenarObjetosAsc } from "../../helpers/ordenador";

export default {
  props: {
    comentarios: {
      type: Array,
      required: true,
    },

    entregaActividad: {
      type: Object,
    },

    idActividad: {
      type: String,
    },

    idClase: {
      type: String,
    },

    idCurso: {
      type: String,
    },

    instrumento: {
      type: String,
    },

    tipoInstrumento: {
      type: String,
    },

    cargarCalificacionesAlumnos: {
      type: Boolean,
      default: false,
    },

    equipos: {
      type: Array,
    },
    
    permisoEscritura: {
      type: Boolean,
      default: false,
    },
    cursoArchivado: { type: Boolean, default: false },
  },

  components: {
    comentario: Comentario,
  },

  data() {
    return {
      alumnos: [],
      calificaciones: [],
      loading: false,
    };
  },

  computed: {
    alumnosConComentarios() {
      return this.alumnos.filter((alumno) => {
        return this.comentarios.some((comentario) => {
          return comentario.usuario._id === alumno._id;
        });
      });
    },
  },

  mounted() {
    if (this.idCurso && this.idActividad && this.cargarCalificacionesAlumnos)
      this.getAlumnos();
  },

  methods: {
    getAlumnos() {
      this.loading = true;

      listadoEntregasActividadService(this.idCurso, this.idActividad)
        .then((serverResponse) => {
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);

          if (serverResponse.alumnos)
            this.alumnos = ordenarObjetosAsc(serverResponse.alumnos, "usuario");
          if (serverResponse.calificaciones)
            this.calificaciones = serverResponse.calificaciones;
        })
        .catch(() => {
          this.loading = false;
          this.$appErrorMessage();
        });
    },
  },
};
</script>
